import React, { useEffect, useState, useRef } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from "reactstrap";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import StudentCardProfile from "../../../../components/feesinvoice/studentCardProfile";
import Select from "react-select";
import { FiArrowLeft } from "react-icons/fi";

import { validateMaxDigits } from "../../../../helper/Validation";

const CreateTransaction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const topRef = useRef(null);

  // State for form data
  const [formData, setFormData] = useState({
    feesType: "",
    feesTypeId: "",
    totalFees: "",
    totalpayableAmount: "",
    balance: "",
    amount: "",
    fine: "",
    dueDate: "",
    paymentDate: "",
    paymentMethod: "",
    paymentStatus: "",
  });

  // State to hold errors
  const [errors, setErrors] = useState({
    fine: "",
    amount: "",
    // other error fields...
  });

  // Additional states
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [fees, setFees] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [transactionId, setTransactionId] = useState(null); // Store the ID of the transaction being edited
  const [initialLoading, setInitialLoading] = useState(true);

  const paymentStatusOptions = [
    { value: "Partial Payment", label: "Partial Payment" },
    { value: "Full Payment", label: "Full Payment" },
  ];
  const [nextDueDateRequired, setNextDueDateRequired] = useState(false);

  const todayDate = (date) => {
    const d = new Date(date);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const today = todayDate(new Date());

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const response = await axiosInstance.get("/paymentmethod");
        setPaymentMethods(response.data); // Assuming response.data is an array of payment methods
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentMethod();
  }, []);

  const paymentMethodOptions = paymentMethods.map((method) => ({
    value: method,
    label: method,
  }));

  const fetchTransactions = async () => {
    try {
      const response = await axiosInstance.get(`/history/transactions/${id}`);
      const fetchedTransactions = response.data.transactions;

      setTransactions(fetchedTransactions); // Store fetched transactions
    } catch (error) {
      console.error(
        "Error fetching transactions:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchFeesById = async () => {
    try {
      const response = await axiosInstance.get(`/details/fees/${id}`);
      setFees(response.data.feesRecord);

      if (response.data.feesRecord) {
        setFormData((prevData) => ({
          ...prevData,
          feesType: response.data.feesRecord.feestype_id.feesType || "",
          feesTypeId: response.data.feesRecord.feestype_id._id || "", // Change this line
          totalFees: response.data.feesRecord.feestype_id.amount || 0,
          paymentDate: today,
          totalpayableAmount: response.data.feesRecord.totalPayableAmount || 0,
          balance:
            response.data.balance !== undefined &&
            response.data.balance !== null
              ? response.data.balance
              : response.data.feesRecord.totalPayableAmount || 0,
        }));
      }


      setStudentData(response.data?.feesRecord?.student_id);
    } catch (error) {
      console.error("Error fetching fees:", error);
    } finally {
      setInitialLoading(false); // Set loading to false after fetching data
    }
  };

  

  // Ensure fetchTransactions is called after fetchFeesById to have access to totalpayableAmount
  useEffect(() => {
    fetchFeesById();
    fetchTransactions();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "paymentStatus") {
      if (value === "Partial Payment") {
        setNextDueDateRequired(true);
      } else if (value === "Full Payment") {
        setNextDueDateRequired(false);
        setFormData((prevData) => ({
          ...prevData,
          dueDate: "",
        }));
      }
    }

    if (name === "amount" || name === "fine") {
      // Only validate when the value is longer than 10 digits
      if (value.length > 10) {
        // Use field names for a clearer error message
        const fieldName = name === "amount" ? "Amount" : "Fine";
        const { isValid, errorMessage } = validateMaxDigits(
          value,
          fieldName,
          10
        );

        if (!isValid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage, // Set specific error message if validation fails
          }));
          return; // Stop further execution if validation fails
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "", // Clear error if valid
          }));
        }
      } else {
        // If the value length is less than 10 digits, clear any previous error
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      }
    }

    // Update the formData state
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = async (transactionId) => {
    try {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling to the top
      }

      // Fetch the transaction details by ID
      const response = await axiosInstance.get(
        `/details/transaction/${transactionId}`
      );
      const { transaction } = response.data;

      // Populate the form data with the fetched transaction details
      setFormData((prevData) => ({
        ...prevData,
        feesType: transaction.feestype_id.feesType || "",
        feesTypeId: transaction.feestype_id._id || "", // Make sure this is correct
        totalFees: transaction.feestype_id.amount || "",
        amount: transaction.amount || "",
        fine: transaction.fine || "",
        dueDate: transaction?.dueDate?.split("T")[0] || "",
        paymentDate: transaction?.paymentDate?.split("T")[0] || "",
        paymentMethod: transaction.paymentMethod || "",
        paymentStatus: transaction.paymentStatus || "",
      }));

      // Set the transaction ID and switch to edit mode
      setTransactionId(transactionId);
      setIsEditing(true);
    } catch (error) {
      console.error("Error fetching transaction for editing:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const transactionData = {
        fees_id: id,
        studentId: studentData?._id,
        branchId: studentData?.branch_id._id,
        feesTypeId: formData.feesTypeId,
        amount: formData.amount,
        fine: formData.fine || 0,
        paymentDate: formData.paymentDate,
        dueDate: formData.dueDate,
        paymentMethod: formData.paymentMethod,
        paymentStatus: formData.paymentStatus,
      };

      if (isEditing) {
        // Update transaction
        await axiosInstance.put(
          `/update/transaction/${transactionId}`,
          transactionData
        );
      } else {
        // Create new transaction
        await axiosInstance.post("/create/transaction", transactionData);
      }

      fetchFeesById();
      fetchTransactions();

      // Reset form data
      setFormData((prevData) => ({
        ...prevData,
        amount: "",
        fine: "",
        dueDate: "",
        paymentDate: "",
        paymentMethod: "",
        paymentStatus: "",
      }));
      setIsEditing(false);
    } catch (error) {
      console.error(
        "Error submitting transaction:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM dd, yyyy");
  };

  const handleCancel = (studentId) => {
    // Reset the editing state and form data
    setIsEditing(false);
    setFormData((prevData) => ({
      ...prevData,
      amount: "",
      fine: "",
      dueDate: "",
      paymentDate: today,
      paymentMethod: "",
    }));

    // Navigate to the /fees/create/:id route if editing is canceled
    if (!isEditing) {
      navigate(`/receivable/create/${studentId}`);
    }
  };

  const generateinvoice = (id) => {
    navigate(`/receivable/create/transaction/invoice/${id}`);
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', 
      textOverflow: 'ellipsis', 
      overflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FDD7C2' 
        : '#FFFFFF', 
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <button onClick={handleBackClick} className="floating-back-button z-3">
        <FiArrowLeft size={24} />
      </button>
      <div ref={topRef}>
        {initialLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "90vh" }}
          >
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            {studentData ? (
              <StudentCardProfile studentData={studentData} />
            ) : (
              ""
            )}
            <Card className=" p-0 mb-2" style={{ marginTop: "10px" }}>
              <CardBody>
                <CardTitle tag="h3" className="mb-5 text-center">
                  {isEditing ? "Edit Transaction" : "  Add New Transaction"}
                </CardTitle>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="feesType">
                          <span style={{ fontWeight: "bold" }}>Fees Type</span>
                        </Label>
                        <Input
                          type="text"
                          name="feesType"
                          id="feesType"
                          value={formData.feesType}
                          disabled // Disable input so it can't be edited
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="totalFees">
                          <span style={{ fontWeight: "bold" }}>Total Fees</span>
                        </Label>
                        <Input
                          type="number"
                          name="totalFees"
                          id="totalFees"
                          value={formData.totalFees}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="totalpayableAmount">
                          <span style={{ fontWeight: "bold" }}>
                            Total Payable Amount
                          </span>
                        </Label>
                        <Input
                          type="number"
                          name="totalpayableAmount"
                          id="totalpayableAmount"
                          value={formData.totalpayableAmount}
                          onChange={handleChange}
                          required
                          disabled
                          step="0.01"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="balance">
                          <span style={{ fontWeight: "bold" }}>
                            Remaining Amount
                          </span>
                        </Label>
                        <Input
                          type="number"
                          name="balance"
                          id="balance"
                          value={formData.balance}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="amount">
                          <span style={{ fontWeight: "bold" }}>Amount*</span>
                        </Label>
                        <Input
                          type="text"
                          name="amount"
                          id="receivedAmount"
                          value={formData.amount}
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                         }}
                          required
                          step="0.01"
                        />
                        {errors.amount && (
                          <div
                            style={{
                              color: "red",
                              marginTop: "0.25rem",
                              fontSize: "0.875rem",
                            }}
                          >
                            {errors.amount}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="fine">
                          <span style={{ fontWeight: "bold" }}>
                            Fine if Applicable
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="fine"
                          id="fine"
                          value={formData.fine}
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                         }}
                          step="0.01"
                        />
                        {errors.fine && (
                          <div
                            style={{
                              color: "red",
                              marginTop: "0.25rem",
                              fontSize: "0.875rem",
                            }}
                          >
                            {errors.fine}
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="paymentDate">
                          <span style={{ fontWeight: "bold" }}>
                            Payment Date*
                          </span>
                        </Label>
                        <Input
                          type="date"
                          name="paymentDate"
                          id="paymentDate"
                          value={formData.paymentDate}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="paymentMethod">
                          <span style={{ fontWeight: "bold" }}>
                            Payment Method*
                          </span>
                        </Label>
                        <Select
                          className="custom-select-input"
                          name="paymentMethod"
                          id="paymentMethod"
                          options={paymentMethodOptions}
                          value={
                            paymentMethodOptions.find(
                              (option) =>
                                option.value === formData.paymentMethod
                            ) || null
                          }
                          onChange={(selectedOption) =>
                            handleChange({
                              target: {
                                name: "paymentMethod",
                                value: selectedOption
                                  ? selectedOption.value
                                  : "",
                              },
                            })
                          }
                          isClearable
                          placeholder="Select Payment Method"
                          styles={customStyles}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="paymentStatus">
                          <span style={{ fontWeight: "bold" }}>
                            Payment Status*
                          </span>
                        </Label>
                        <Select
                          className="custom-select-input"
                          name="paymentStatus"
                          id="paymentStatus"
                          options={paymentStatusOptions}
                          value={
                            paymentStatusOptions.find(
                              (option) =>
                                option.value === formData.paymentStatus
                            ) || null
                          }
                          onChange={(selectedOption) =>
                            handleChange({
                              target: {
                                name: "paymentStatus",
                                value: selectedOption
                                  ? selectedOption.value
                                  : "",
                              },
                            })
                          }
                          isClearable
                          placeholder="Select Payment Status"
                          styles={customStyles}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="dueDate">
                          <span style={{ fontWeight: "bold" }}>
                            Next Due Date*
                          </span>
                        </Label>
                        <Input
                          type="date"
                          name="dueDate"
                          id="dueDate"
                          value={formData.dueDate}
                          onChange={handleChange}
                          required={nextDueDateRequired} // Conditionally required
                          disabled={formData.paymentStatus === "Full Payment"} // Disabled if 'Full Paid'
                        />
                      </FormGroup>
                    </Col>

                    {/* <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="dueDate">
                    <span style={{ fontWeight: "bold" }}>Next Due Date</span>
                  </Label>
                  <Input
                    type="date"
                    name="dueDate"
                    id="dueDate"
                    value={formData.dueDate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col> */}
                  </Row>
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      className="btn-no-radius"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner size="sm" color="light" /> Saving...
                        </>
                      ) : (
                        "Save"
                      )}
                    </Button>

                    <Button
                      color="secondary"
                      onClick={() => handleCancel(studentData?._id)} // Pass the studentId here
                      className="btn-no-radius mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Card className=" px-3 pt-0">
              <div style={{ overflowX: "auto", marginTop: "20px" }}>
                <Table bordered striped responsive>
                  <thead
                    style={{ backgroundColor: "#f8f9fa", color: "#495057" }}
                  >
                    <tr>
                      <th>Fees Type</th>
                      <th>Invoice Id</th>
                      <th>Payment Date</th>
                      <th>Payment Method</th>
                      <th>Amount</th>
                      <th>Fine</th>
                      <th>Due Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.length > 0 ? (
                      transactions.map((val, index) => (
                        <tr key={index}>
                          <td>{val.feestype_id.feesType}</td>
                          <td>{val.invoiceId}</td>
                          <td>
                            {val.paymentDate
                              ? formatDate(val.paymentDate || "")
                              : ""}
                          </td>
                          <td>{val.paymentMethod}</td>
                          <td>{val.amount}</td>
                          <td>{val.fine}</td>
                          <td>
                            {val.dueDate
                              ? formatDate(val.dueDate || "")
                              : "N/A"}
                          </td>
                          <td>
                            <div className="d-flex ">
                              <Button
                                className="btn-no-radius px-4 responsive-button"
                                color="primary"
                                onClick={() => handleEdit(val._id)}
                              >
                                Edit
                              </Button>
                              <Button
                                className="btn-no-radius ms-2 responsive-button"
                                color="primary"
                                onClick={() => generateinvoice(val._id)}
                              >
                                Generate Invoice
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No Transaction History Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* CSS for table styling */}
                <style jsx="true">{`
                  table td {
                    white-space: nowrap;
                  }
                  table th {
                    white-space: nowrap;
                  }

                  @media (max-width: 768px) {
                    table td,
                    table th {
                      padding: 5px;
                      white-space: nowrap;
                    }
                  }

                  @media (max-width: 576px) {
                    table td,
                    table th {
                      white-space: nowrap;
                    }
                  }
                `}</style>
              </div>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default CreateTransaction;
