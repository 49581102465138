import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  token: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  isfirstime:false,
  expirereminder:null,
  isExpired:false,
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/adminlogin`, userData, {
        withCredentials: true, 
      });
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/adminregister`, userData, {
        withCredentials: true, 
      });
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || err.message);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/refresh-token`,{
        withCredentials: true, 
      });
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || err.message);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutSuccess: state => {
      state.token = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
     
    },
    updateFirstLogin: (state, action) => {
      state.isfirstime = action.payload; 
    },
    isExpired: (state, action) => {
      state.isExpired = action.payload; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
        state.isfirstime = action.payload.isfirstime;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
       
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.token; 
        state.loading = false;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addMatcher(
        (action) => action.type.endsWith('/rejected'), 
        (state, action) => {
          state.loading = false; 
          state.error = action.payload;
        }
      );;
      
  },
});

export const { logoutSuccess, updateFirstLogin, isExpired } = authSlice.actions;

export const selectToken = state => state.auth.token;
export const selectIsAuthenticated = state => state.auth.isAuthenticated;
export const selectLoading = state => state.auth.loading;
export const selectError = state => state.auth.error;
export const selectIsExpired = state => state.auth.isExpired;


export default authSlice.reducer;
