import React from 'react';
import {
  Card,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap";

// const studentCardProfile = ({ image, firstname, lastname , registerno, branch , studentclass , section , rollno}) => {
const studentCardProfile = ({studentData}) => {
  
  const getInitial = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };

  return (
    <>
     <div style={{
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '0px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        width: '100%',
        //  Width: '1200px',
        margin: '0px',
        overflowX:'hidden',
      }}>
        <Row className='p-0 m-0'>
          <Col md="4" style={{ display: 'flex', justifyContent: 'center' }}>
          {studentData?.image ? (
            <img
            src={studentData?.image || 'default-image-url'}
            alt="Student"
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'contain',
              borderRadius: '0px',
              marginTop: '10px'
            }}
          /> ) : (
            <div
                      className="square mt-2 mt-md-0"
                      style={{
                        width: '200px',
                        height: '200px',
                        backgroundColor: '#fb0f01',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf:"center",
                        fontSize: '60px',
                        fontWeight: 'bold',
                      }}
                    >
                      {getInitial(studentData?.firstName)}
                    </div>
                     )}
          </Col>
          
          <Col md="8" className="p-3">
            <CardTitle tag="h4" className="mb-3">
              {studentData?.firstName} {studentData?.lastName}
            </CardTitle>
            <CardText>
              <strong>Register Number:</strong> {studentData?.registerNo}
            </CardText>
            <CardText> <strong>Branch: </strong>{studentData?.branch_id ? `${studentData.branch_id.branchName}` : 'N/A'}</CardText>
            <CardText>
              <strong>Class:</strong> {studentData?.class_id?.className}{studentData?.section_id ? ` (${studentData.section_id.sectionName})` : ''}
            </CardText>
            <CardText>
              <strong>Roll Number:</strong> {studentData?.roll}
            </CardText>
          </Col>
        </Row>
      </div>
  {/* <Card style={{ boxShadow: "none", marginBottom: "5px" }}>
        <div style={{
          backgroundColor: 'white',
          padding: '0px',
          borderRadius: '0px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          width: '100%',
          Width: '1200px',
          margin: '0px',
          overflowX:'hidden',
        }}>
          <Row className='p-0 m-0'>
            <Col md="4" style={{ display: 'flex', justifyContent: 'center' }}>
              {image ? (
                <img
                  src={image || "default-image-url"}
                  alt="Student"
                  style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'contain',
                    borderRadius: '0px',
                    marginTop: '10px'
                  }}
                />
              ) : (
                <div
                  className="square mt-2 mt-md-0"
                  style={{
                    width: "200px",
                    height: "200px",
                    backgroundColor: "#fb0f01",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    fontSize: "60px",
                    fontWeight: "bold",
                  }}
                >
                  {getInitial(firstname)}
                </div>
              )}
            </Col>

            <Col md="8" className="p-3">
              <CardTitle tag="h4" className="mb-3">
                {firstname
                  ? `${firstname} ${lastname}`
                  : "Student Name"}
              </CardTitle>
              <CardText>
                <strong>Register Number:</strong>{" "}
                {registerno || "N/A"}
              </CardText>
              <CardText>
                <strong>Branch:</strong>{" "}
                {branch || "N/A"}
              </CardText>
              <CardText>
                <strong>Class:</strong> {studentclass}
                {section
                  ? ` (${section})`
                  : ""}
              </CardText>
              <CardText>
              <strong>Roll Number:</strong> {rollno}
            </CardText>
            </Col>
          </Row>
        </div>
      </Card> */}
    </>
  );
};

export default studentCardProfile;
