import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import {
  validateMaxDigits,
  validateDiscount,
} from "../../../../helper/Validation";
import Select from "react-select";
import { FiArrowLeft } from "react-icons/fi";

const CreateFees = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const topRef = useRef(null);

  // State for form data
  const [formData, setFormData] = useState({
    feesTypeId: "",
    feesType: "",
    totalFees: "",
    totalpayableAmount: "",
    balance: "",
    discount: "",
  });

  // State to hold errors
  const [errors, setErrors] = useState({
    discount: "",
    // other error fields...
  });

  // Additional states
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [feesTypes, setFeesTypes] = useState([]);
  const [isDiscountDisabled, setIsDiscountDisabled] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [allStudentFees, setAllStudentFees] = useState([]);
  const [DataforEdit, SetDataforEdit] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [academicYears, setAcademicYears] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const getInitial = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/singlestudent/${id}`);
        setStudentData(response.data);
        const academicYearId = response.data.academicyear_id._id;
        fetchAcademicYears(academicYearId);
        fetchStudentDataByAcademicY(response?.data?.academicyear_id?._id);
      } catch (err) {
        console.error("Error fetching student data:", err);
      } finally {
        setInitialLoading(false); // Set loading to false after fetching data
      }
    };

    fetchStudentData();
  }, [id]);

  const fetchAcademicYears = async (academicYearId) => {
    try {
      const response = await axiosInstance.get("/academicyears");
      setAcademicYears(response.data);

      const matchedYear = response.data.find((ay) => ay._id === academicYearId);

      if (matchedYear) {
        setFormData((prevState) => ({
          ...prevState,
          academicYear: matchedYear._id,
        }));
      }
    } catch (error) {
      console.error("Error fetching academic years:", error);
    } finally {
      setInitialLoading(false); // Set loading to false after fetching data
    }
  };


  const handleAcademicYearChange = (selectedOption) => {
    if (selectedOption?.value) {
      fetchStudentDataByAcademicY(selectedOption.value);
      // if (studentData?.branch_id?._id && studentData?.class_id?._id) {
      //   fetchFeesTypes(
      //     studentData.branch_id._id,
      //     studentData.class_id._id,
      //     selectedOption.value
      //   );
      // }
      if (selectedOption.value === studentData?.academicyear_id?._id) {
        fetchFeesTypes(
          studentData.branch_id._id,
          studentData.class_id._id,
          studentData?.academicyear_id?._id
        );
      }
      setFeesTypes([])
    }

    setFormData({
      ...formData,
      feesTypeId: "",
      feesType: "",
      totalFees: "",
      totalpayableAmount: "",
      balance: "",
      discount: "",
      academicYear: selectedOption?.value || "", // Only academicYear is updated
    });

    // setFormData({
    //   ...formData,
    //   academicYear: selectedOption ? selectedOption.value : ''
    // })
  };

  const fetchFeesTypes = async (branchId, classId, academicYearId) => {
    try {
      const response = await axiosInstance.get("/feestype", {
        params: { branchId, classId, academicYearId },
      });
      setFeesTypes(response.data);
    } catch (error) {
      console.error("Error fetching fees types:");
    } finally {
      setInitialLoading(false); // Set loading to false after fetching data
    }
  };

  const fetchStudentDataByAcademicY = async (academicYear) => {
    try {
      const response = await axiosInstance.post(`/fees/allstudentfees/${id}`, {
        academicYear,
      });

      setAllStudentFees(response.data.feeDetails);
    } catch (err) {
      console.error("Error fetching student data:", err);
    } finally {
      setInitialLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    if (studentData?.branch_id?._id && studentData?.class_id?._id) {
      fetchFeesTypes(
        studentData.branch_id._id,
        studentData.class_id._id,
        studentData.academicyear_id._id
      );
    }
  }, [studentData]);


  const handleChange = async (e) => {
    const { name, value } = e.target;

    
    if (name === "feesTypeId") {
      const selectedFeesType = feesTypes.find(
        (type) => type._id === value
      );
      
      const totalFees = selectedFeesType ? selectedFeesType.amount : 0;

      setFormData((prevState) => ({
        ...prevState,
        discount:'',
        feesTypeId: value,
        feesTypeLabel: selectedFeesType.feesType,
        balance: totalFees, // Set balance to totalFees on error
        totalFees: totalFees,
        totalpayableAmount: totalFees, // Set totalFees from the selected feesType
      }));

    } else {
      let updatedFormData = { ...formData, [name]: value };

     
      if (name === "discount") {
        const { isValid, errorMessage } = validateDiscount(value);

        if (!isValid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            discount: errorMessage,
          }));
          return;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            discount: "",
          }));
        }
      }

      if (name === "discount") {
     
        const discountPercentage = parseFloat(updatedFormData.discount) || 0;
        const totalFees = parseFloat(formData.totalFees) || 0;

        const discountAmount = (totalFees * discountPercentage) / 100;

        let totalpayableAmount;
   
        totalpayableAmount = totalFees - discountAmount;
   


        if (totalpayableAmount < 0.5) {
          totalpayableAmount = Math.floor(totalpayableAmount);
      } else {
          totalpayableAmount = Math.round(totalpayableAmount);
      }

        if (totalpayableAmount < 0) {
          Swal.fire({
            title: "Warning!",
            text: "The discount is making the payable amount negative. It will be set to 0.",
            icon: "warning",
            confirmButtonText: "OK",
          });
          totalpayableAmount = 0;
        }

        updatedFormData.totalpayableAmount = totalpayableAmount;

        setFormData(updatedFormData);
      }
      setFormData(updatedFormData);
    }
  };

  const forEditFun = async (fee) => {
    setisEdit(true);
    try {
      const response = await axiosInstance.get(`/fees/getfees/${fee.feeId}`);
      setFormData((prevState) => ({
        ...prevState,
        feesTypeId: response.data.Fees.feestype_id._id,
        feesTypeLabel: response.data.Fees.feestype_id.feesType, 
        totalFees: response.data.Fees.feestype_id.amount,
        discount: response.data.Fees.totalDiscount,
        totalpayableAmount: response.data.Fees.totalPayableAmount,
      }));

      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling to the top
      }

      SetDataforEdit(response.data);
    } catch (error) {
      console.error("Error fetching fees types:");
    }
    //setisEdit(false);
  };

  const handleTransaction = (id) => {
    navigate(`/receivable/create/transaction/${id}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { isValid: isDiscountValid, errorMessage: discountError } =
      validateDiscount(formData.discount);
    if (!isDiscountValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        discount: discountError,
      }));
      setLoading(false);
      return;
    }

    if (isEdit === false) {


      try {
        const transactionData = {
          orgId: studentData?.org_id,
          // academicYearId: studentData?.academicyear_id._id,
          academicYearId: formData?.academicYear,
          branchId: studentData?.branch_id._id,
          // feesType: formData.feesType._id,
          classId: studentData?.class_id._id,
          sectionId: studentData?.section_id?._id || null, // Optional
          studentId: studentData?._id,
          feesTypeId: formData.feesTypeId,
          amount: formData.amount,
          totalFees: formData.totalFees,
          totalpayableAmount: formData.totalpayableAmount,
          discount: formData.discount || 0,
        };

        await axiosInstance.post("/fees/createfees", transactionData);
        setFormData((prevData) => ({
          ...prevData,
          feesTypeId: "",
          feesType: "",
          totalFees: "",
          totalpayableAmount: "",
          discount: "",
        }));
        fetchStudentDataByAcademicY(transactionData.academicYearId);

      } catch (error) {
        // console.error('Error creating transaction:', error)
      } finally {
        setLoading(false);
      }
    } else {
      
      try {
        const feesId = DataforEdit.Fees._id;
        const transactionData = {
          orgId: studentData?.org_id,
          academicYearId: studentData?.academicyear_id._id,
          feesTypeId: DataforEdit.Fees.feestype_id._id,
          feesType: formData.feesType,
          discount: formData.discount || 0,
          feesId,
        };

        await axiosInstance.post(`/fees/editfees/`, transactionData);
        setFormData((prevData) => ({
          ...prevData,
          feesTypeId: "",
          feesType: "",
          totalFees: "",
          totalpayableAmount: "",
          discount: "",
        }));

        setisEdit(false);
        fetchStudentDataByAcademicY(formData?.academicYear);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    }
  };

  const [feeTypes, setFeeTypes] = useState([]);


  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', 
      textOverflow: 'ellipsis', 
      overflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FDD7C2' 
        : '#FFFFFF', 
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <button onClick={handleBackClick} className="floating-back-button z-3">
        <FiArrowLeft size={24} />
      </button>
      <div ref={topRef}>
        {initialLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "90vh" }}
          >
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <div
              style={{
                backgroundColor: "white",
                padding: "0px",
                borderRadius: "0px",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <Row className="p-0 m-0">
                <Col
                  md="4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {studentData && studentData.image ? (
                    <img
                      src={studentData.image || "default-image-url"}
                      alt="Student"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                        borderRadius: "0px",
                        marginTop: "10px",
                      }}
                    />
                  ) : (
                    <div
                      className="square mt-2 mt-md-0"
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "#fb0f01",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        fontSize: "60px",
                        fontWeight: "bold",
                      }}
                    >
                      {getInitial(studentData?.firstName)}
                    </div>
                  )}
                </Col>

                <Col md="8" className="p-3">
                  <CardTitle tag="h4" className="mb-3">
                    {studentData
                      ? `${studentData.firstName} ${studentData.lastName}`
                      : "Student Name"}
                  </CardTitle>
                  <CardText>
                    <strong>Academic Year:</strong>{" "}
                    {studentData?.academicyear_id.year || "N/A"}
                  </CardText>
                  <CardText>
                    <strong>Register Number:</strong>{" "}
                    {studentData?.registerNo || "N/A"}
                  </CardText>
                  <CardText>
                    <strong>Branch:</strong>{" "}
                    {studentData?.branch_id?.branchName || "N/A"}
                  </CardText>
                  <CardText>
                    <strong>Class:</strong> {studentData?.class_id?.className}
                    {studentData?.section_id
                      ? ` (${studentData.section_id.sectionName})`
                      : ""}
                  </CardText>
                </Col>
              </Row>
            </div>
            <Card style={{ marginTop: "10px" }}>
              <CardBody>
                <CardTitle tag="h6" className="mb-2">
                  Select Academic Year
                </CardTitle>
                <Col xs={12} sm={6} md={3} xl={3} className="mb-4">
                  <Select
                    name="academicYear"
                    id="academicYear"
                    options={academicYears.map((ay) => ({
                      value: ay._id, 
                      label: ay.year, 
                    }))}
                    value={
                      formData.academicYear
                        ? {
                            value: formData.academicYear,
                            label:
                              academicYears.find(
                                (ay) => ay._id === formData.academicYear
                              )?.year || "Select Academic Year",
                          }
                        : null
                    }
                    onChange={handleAcademicYearChange} // Handle selection change
                    isSearchable // Allow searching in the dropdown
                    placeholder="Select Academic Year"
                    styles={customStyles} // Custom styles passed
                  />
                </Col>
                <hr/>
                <CardTitle tag="h3" className="mb-5 text-center">
                  {isEdit ? "Edit Fees" : "Add Fees"}
                </CardTitle>

                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="feesTypeId">
                          <span style={{ fontWeight: "bold" }}>Fees Type</span>
                        </Label>
                        <Select
                          name="feesTypeId"
                          id="feesTypeId"
                          options={feesTypes.map((type) => ({
                            value: type._id,
                            label: type.feesType,
                          }))}
                          value={
                            formData.feesTypeId
                              ? {
                                  value: formData.feesTypeId,
                                  label: formData.feesTypeLabel
                                  // label: feesTypes.find(
                                  //   (type) => type._id === formData.feesTypeId
                                  // )?.feesType,
                                }
                              : null
                          }
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: "feesTypeId",
                                value: selectedOption
                                  ? selectedOption.value
                                  : "",
                              },
                            });
                          }}
                          placeholder="Select Fees Type" // Placeholder text
                          isSearchable
                          required
                          styles={customStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="totalFees">
                          <span style={{ fontWeight: "bold" }}>Total Fees</span>
                        </Label>
                        <Input
                          type="number"
                          name="totalFees"
                          id="totalFees"
                          value={formData.totalFees}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="discount">
                          <span style={{ fontWeight: "bold" }}>
                            Discount(%)
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="discount"
                          id="discount"
                          value={formData.discount}
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                         }}
                          disabled={isDiscountDisabled}
                          step="0.01"
                        />
                        {errors.discount && (
                          <div
                            style={{
                              color: "red",
                              marginTop: "0.25rem",
                              fontSize: "0.875rem",
                            }}
                          >
                            {errors.discount}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="totalpayableAmount">
                          <span style={{ fontWeight: "bold" }}>
                            Total Payable Amount
                          </span>
                        </Label>
                        <Input
                          type="number"
                          name="totalpayableAmount"
                          id="totalpayableAmount"
                          value={formData.totalpayableAmount}
                          onChange={handleChange}
                          required
                          disabled
                          step="0.01"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className=" text-end">
                    <Button
                      className="btn-no-radius px-5"
                      color="primary"
                      // onClick={}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner size="sm" color="light" /> Saving...
                        </>
                      ) : isEdit ? (
                        "Save"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </div>
                  </Form>
                  </CardBody>
                  </Card>

                  <Card style={{ marginTop: "-20px" }}>
                  <CardBody>
                  <div>
                    <CardTitle tag="h5" className="text-start mb-3 me-5 mt-1">
                      Transactions Details:
                    </CardTitle>
                    <div style={{ overflowX: "auto", marginTop: "20px" }}>
                      <Table bordered striped responsive>
                        <thead
                          style={{
                            backgroundColor: "#f8f9fa",
                            color: "#495057",
                          }}
                        >
                          <tr>
                            <th>Class</th>
                            <th>Fees Type</th>
                            <th>Total fees</th>
                            <th>Discount(%)</th>
                            <th>Total Payable Fees</th>
                            <th>Remaining Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allStudentFees.length > 0 ? (
                            allStudentFees.map((fees, index) => (
                              <tr key={index}>
                              <td>{fees.class}</td>
                                <td>{fees.feeType}</td>
                                <td>{fees.totalAmount}</td>
                                <td>{fees.totalDiscount}</td>
                                <td>{fees.totalPayableAmount}</td>
                                <td>{fees.Remaining}</td>
                                <td>{fees.status}</td>
                                <td>
                                  <div className="d-flex">
                                    <Button
                                      className="btn-no-radius mx-2 responsive-button"
                                      color="primary"
                                      onClick={() =>
                                        handleTransaction(fees?.feeId)
                                      }
                                    >
                                      Transactions
                                    </Button>
                                    <Button
                                      className="btn-no-radius responsive-button"
                                      color="secondary"
                                      onClick={() => forEditFun(fees)}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center">
                                No Fees Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      {/* CSS for table styling */}
                      <style jsx="true">{`
                        table td {
                          white-space: nowrap;
                        }
                        table th {
                          white-space: nowrap;
                        }

                        @media (max-width: 768px) {
                          table td,
                          table th {
                            padding: 5px;
                            white-space: nowrap;
                          }
                        }

                        @media (max-width: 576px) {
                          table td,
                          table th {
                            white-space: nowrap;
                          }
                        }
                      `}</style>
                    </div>
                  </div>
                
              </CardBody>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default CreateFees;
