import React, { useEffect, useState } from 'react';
import {  Card, CardTitle, Table, Button, Spinner } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaRegEdit, FaFileAlt } from 'react-icons/fa';
import { AiOutlineDelete } from "react-icons/ai";
import axiosInstance from '../../../middleware/axiosInstance';

const ShowBranches = () => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get('/branches');
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchBranches();
  }, []);

  const handleEditBranch = (id) => {
    navigate(`/updatebranch/${id}`);
  };

  const handleDeleteBranch = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(`/deletebranch/${id}`);
          setBranches(branches.filter(branch => branch._id !== id));
        } catch (error) {
          console.error('Error deleting branch:', error);
        }
      }
    });
  };

  const handleAdd = () => {
    navigate('/createbranch')
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '90vh' }}>
          <Spinner color="primary" />
        </div>
      ) : branches.length === 0 ? (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '90vh' }}>
          <div className="text-center">
            <div className="d-flex flex-column align-items-center">
              <FaFileAlt size={90} color="#888" />
              <h4 className="alert-heading mb-3 mt-3">No Branches Found</h4>
              <NavLink to="/createbranch">
                <Button className='btn-no-radius theme-button' color="primary">Add New Branch</Button>
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        <div>
              <Card  style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }} className='p-1 px-3'>
          <div className="d-flex justify-content-between align-items-center  ">
            <CardTitle tag="h4" className='mt-2' >
              Branch List
            </CardTitle>
            <Button onClick={handleAdd} color='primary' style={{ border: "none" }} className='btn-no-radius theme-button'>
              Add Branch
            </Button>
          </div>
          </Card>
          <Card  className=" px-3 pt-0" style={{ borderRadius: '0', minHeight: '400px'}}>
          <div style={{ overflowX: 'auto' }}>
          <Table bordered striped responsive className='mt-3'>
            <thead style={{ backgroundColor: '#f8f9fa', color: '#495057' }}>
              <tr>
                <th>No</th>
                <th>Logo</th>
                <th>Branch</th>
                <th>Organization</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {branches.map((branch, index) => (
                <tr key={branch._id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {branch.image ? (
                      <img
                        src={branch.image}
                        alt={branch.branchName}
                        style={{
                          width: '80px',
                          height: '40px',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <span>No Logo</span>
                    )}
                  </td>
                  <td>{branch.branchName}</td>
                  <td>{branch.org_id.organizationName}</td>
                  <td>
                    <FaRegEdit className="mx-2" style={{ color: "black", cursor: 'pointer' }} onClick={() => handleEditBranch(branch._id)} />
                    <AiOutlineDelete className="mx-2" style={{ color: "black", cursor: 'pointer' }} onClick={() => handleDeleteBranch(branch._id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <style jsx="true">{`
              table td, table th {
                white-space: nowrap; 
              }

              @media (max-width: 768px) {
                table td, table th {
                  padding: 5px;
                }
              }

              @media (max-width: 576px) {
                table td, table th {
                }
              }
            `}</style>
          </div>
          </Card>
        </div>
      )}

    </>
  );
};

export default ShowBranches;
