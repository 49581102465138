import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardTitle, CardText } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";

import axiosInstance from "../../../middleware/axiosInstance";
import { format } from "date-fns";
import { FiArrowLeft } from 'react-icons/fi';
import './student.css';

const SingleStudent = () => {
  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/singlestudent/${id}`);
        setStudentData(response.data);
      } catch (err) {
        console.error("Error fetching student data:", err);
        navigate("/students");
      }
    };
    fetchStudentData();
  }, [id, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
     if (isNaN(date.getTime()) || date.toISOString().startsWith('1970-01-01')) {
      return "N/A";
    }
  
    return format(date, "MMMM dd, yyyy");
  };
  

  const getInitial = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };
  if (!studentData) {
    return <p>Loading...</p>;  
  }


  const showSection =
    studentData.section_id &&
    studentData.section_id.sectionName &&
    studentData.section_id.sectionName !== "N/A";



  const handleBackClick = () => {
    navigate(-1);  
  };

  return (
    <>
    <div>
      <button onClick={handleBackClick} className="floating-back-button">
        <FiArrowLeft size={24} />  
      </button>

     </div>
      <div
        style={{ backgroundColor: "white" }}
        className="d-flex justify-content-between align-items-center pb-1 px-3 pt-2"
      >
        <CardTitle tag="h4">Student Details</CardTitle>
      </div>
      <hr className="my-0" />
      <div>
        <Row className="m-0 p-0 mt-2">
          <Col md="3" className="m-0 p-0 pe-0 pe-md-2 ">
            <Card>
              <div
                style={{ backgroundColor: "#f8f9fa" }}
                className="pe-2 ps-0 d-flex flex-column align-items-center"
              >
                {studentData.image ? (
                  <img
                    className="rounded-circle mt-3"
                    src={studentData.image}
                    alt="Student Image"
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "40px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle"
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: "#fb0f01",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "40px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    {getInitial(studentData.firstName)}
                  </div>
                )}

                <div>
                  <CardTitle tag="h4">
                    {studentData.firstName} {studentData.lastName}
                  </CardTitle>
                </div>
                <CardText tag="h6" className="mb-3">
                  ({studentData.academicyear_id.year})
                </CardText>
              </div>

              <div className="m-3">
                <div className="d-flex justify-content-between">
                  <CardText className="">
                    <strong>Roll Number:</strong>
                  </CardText>
                  <CardText>
                    <span style={{ color: "blue" }}> {studentData.roll}</span>
                  </CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="">
                    <strong>Class:</strong>
                  </CardText>
                  <CardText>
                    {studentData.class_id?.className || "N/A"}
                    {showSection
                      ? `(${studentData.section_id?.sectionName || "N/A"})`
                      : ""}
                  </CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="">
                    <strong>Gender:</strong>
                  </CardText>
                  <CardText>{studentData.gender}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="">
                    <strong>Register No:</strong>
                  </CardText>
                  <CardText> {studentData.registerNo}</CardText>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="ps-0 pe-0 " md="9">
            <Card className=""> 
              <div className="m-3 me-sm-4">
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Branch:</strong>
                  </CardText>
                  <CardText>
                    {studentData.branch_id
                      ? `${studentData.branch_id.branchName}`
                      : "N/A"}
                  </CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Admission Date :</strong>
                  </CardText>
                  <CardText>
                    {studentData.admissionDate
                      ? formatDate(studentData.admissionDate)
                      : "N/A"}
                  </CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Email :</strong>
                  </CardText>
                  <CardText>{studentData.email || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mobile Number :</strong>
                  </CardText>
                  <CardText>{studentData.mobileNumber || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Date of Birth :</strong>
                  </CardText>
                  <CardText>{formatDate(studentData.dateOfBirth)}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Category :</strong>
                  </CardText>
                  <CardText>{studentData.category || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Caste :</strong>
                  </CardText>
                  <CardText>{studentData.caste || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Religion :</strong>
                  </CardText>
                  <CardText>{studentData.religion || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Blood Group :</strong>
                  </CardText>
                  <CardText>{studentData.bloodGroup || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mother Tongue :</strong>
                  </CardText>
                  <CardText>{studentData.motherTongue || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Aadhaar Number :</strong>
                  </CardText>
                  <CardText>{studentData.aadhaarNo || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Nationality :</strong>
                  </CardText>
                  <CardText>{studentData.nationality || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>State :</strong>
                  </CardText>
                  <CardText>{studentData.state || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>District :</strong>
                  </CardText>
                  <CardText>{studentData.district || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>City/Town/Village :</strong>
                  </CardText>
                  <CardText>{studentData.city || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Tahsil :</strong>
                  </CardText>
                  <CardText>{studentData.tahsil || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="w-50">
                    <strong>Address :</strong>
                  </CardText>
                  <CardText className="text-end">{studentData.address || "N/A"}</CardText>
                </div>
              </div>
              <div style={{ backgroundColor: "#f8f9fa" }} className="px-3 pt-3">
                <CardTitle tag="h4">Guardian's Details</CardTitle>
              </div>
              <div className="m-3 me-sm-5">

              <div className="pt-2 mb-4">
                <CardText>
                <strong style={{ fontSize: '1.20rem' }}>Mother's Details</strong>
              </CardText>
              </div>
              
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mother's First Name :</strong>
                  </CardText>
                  <CardText>{studentData.motherName || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Student's Relation :</strong>
                  </CardText>
                  <CardText>{studentData.motherrelation || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Occupation :</strong>
                  </CardText>
                  <CardText>{studentData.motheroccupation || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Annual Income :</strong>
                  </CardText>
                  <CardText>{studentData.motherincome || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Education:</strong>
                  </CardText>
                  <CardText>{studentData.mothereducation || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mother's Phone Number :</strong>
                  </CardText>
                  <CardText>{studentData.motherMobileNo || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mother's Email :</strong>
                  </CardText>
                  <CardText>{studentData.motherEmail || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mother's State :</strong>
                  </CardText>
                  <CardText>{studentData.motherState || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Mother's City/Town/Village :</strong>
                  </CardText>
                  <CardText>{studentData.motherCity || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="w-100">
                    <strong>Mother's Address :</strong>
                  </CardText>
                  <CardText className="text-end">{studentData.motherAddress || "N/A"}</CardText>
                </div>

                
              <div className="pt-1 mb-4">
              <CardText>
                <strong style={{ fontSize: '1.20rem' }}>Father's Details</strong>
              </CardText>
              </div>


                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Father's First Name :</strong>
                  </CardText>
                  <CardText>{studentData.fatherName || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Student's Relation :</strong>
                  </CardText>
                  <CardText>{studentData.fatherrelation || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Occupation :</strong>
                  </CardText>
                  <CardText>{studentData.fatheroccupation || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Annual Income :</strong>
                  </CardText>
                  <CardText>{studentData.fatherincome || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Education:</strong>
                  </CardText>
                  <CardText>{studentData.fathereducation || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText >
                    <strong>Father's Phone Number :</strong>
                  </CardText>
                  <CardText>{studentData.fatherMobileNo || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Father's Email :</strong>
                  </CardText>
                  <CardText>{studentData.fatherEmail || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Father's State :</strong>
                  </CardText>
                  <CardText>{studentData.fatherState || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Father's City/Town/Village :</strong>
                  </CardText>
                  <CardText>{studentData.fatherCity || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="w-100">
                    <strong>Father's Address :</strong>
                  </CardText>
                  <CardText className="text-end">{studentData.fatherAddress || "N/A"}</CardText>
                </div>
              </div>


              <div style={{ backgroundColor: "#f8f9fa" }} className="px-3 pt-3">
                <CardTitle tag="h4">Previous School Details</CardTitle>
              </div>
              <div className="m-3 me-sm-5">
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Previous School :</strong>
                  </CardText>
                  <CardText>{studentData.previousSchoolName || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText>
                    <strong>Qualification :</strong>
                  </CardText>
                  <CardText>{studentData.qualification || "N/A"}</CardText>
                </div>
                <div className="d-flex justify-content-between">
                  <CardText className="w-100">
                    <strong>Remark :</strong>
                  </CardText>
                  <CardText className=" text-end">{studentData.remarks || "N/A"}</CardText>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};


export default SingleStudent;



