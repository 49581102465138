import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; 
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";

import StudentCardProfile from "../../../../components/feesinvoice/studentCardProfile";
import { format } from "date-fns";
import { FiArrowLeft } from 'react-icons/fi';



const generateInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();

const [studentData, setStudentData] = useState(null);
const [transactionData, setTransactionData] = useState(null);
const [loading, setLoading] = useState(false);
const [lastdate, setlastdate] = useState(false);
const [invoice, setInvoice] = useState(null);
const [invoicesending, setInvoiceSending] = useState(false);

const [isModalOpen, setIsModalOpen] = useState(false);
const [email, setEmail] = useState('');


  // useEffect(() => {
  //   // When studentData is fetched, update the email state
  //   if (studentData) {
  //     setEmail(studentData.email || '');
  //   }
  // }, [studentData]);

const handleGenerateInvoice = async () => {
  await GenerateInvoice();
  setIsModalOpen(true); // Open modal when the invoice is generated
};

const toggleModal = () => setIsModalOpen(!isModalOpen);


const formatDate = (dateString) => {
    const date = new Date(dateString);
    
     if (isNaN(date.getTime()) || date.toISOString().startsWith('1970-01-01')) {
      return "N/A";
    }
  
    return format(date, "MMMM dd, yyyy");
  };
  
  useEffect(() => {

    const fetchTransaction = async () => {
        try {
            // const data = { academicYear: formData.academicYear };
            const response = await axiosInstance.post(`/transaction-history/${id}`); 
            setStudentData(response.data.transactions?.student_id);
            setTransactionData(response.data.transactions);
            setInvoice(response.data.transactions?.generatedInvoice?.url);
            setlastdate(response.data.transactions?.generatedInvoice?.lastGeneratedDate ? format(new Date(response.data.transactions?.generatedInvoice?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)")  : ' Not generated yet');
            setEmail(response.data.transactions?.student_id?.email);
          } catch (err) {

         console.error("Error fetching Transactions:", err);
        }
    };
    fetchTransaction()
}, [id]);

const GenerateInvoice = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/generateinvoice/${id}`);
      const formattedDate = format(new Date(response.data?.responsedata?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)");
      setlastdate(formattedDate || '')
      setInvoice(response.data?.responsedata?.url || '');
    } catch (err) {
      console.error('Error generating Leaving Certificate:', err);
    } finally {
      setLoading(false);
    }
  };

  const SendInvoice = async () => {
    setInvoiceSending(true);
    try {
      await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/sendgenerateinvoice/${id}`, {
        email: email
      });
    } catch (err) {
      console.error('Error generating Leaving Certificate:', err);
    } finally {
      setInvoiceSending(false);
    }
  };

const handleDownload = async (url, filename) => {
    try {
    const mainresponse = await axiosInstance.post('/api/download', { url });
    if (mainresponse?.data?.url)
    {
       const response = await fetch(mainresponse.data.url);
      const blob = await response.blob();
      const contentType = response.headers.get("Content-Type");

      let extension = '';
  
      if (contentType) {
        extension = contentType.split("/")[1]; 
      } else {
        extension = url.split('.').pop().split(/\#|\?/)[0];
      }

      const finalFilename = `${studentData.roll}_${studentData.firstName}_${studentData.lastName}_${filename}.${extension}`; 
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', finalFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    } catch (error) {
      console.error("Download failed:", error);
      Swal.fire({
        title: 'Warning',
        text: 'Server is facing some issue, please try again later',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleBackClick = () => {
    navigate(-1);  
  };

  return (
    <>
{ studentData ? (<StudentCardProfile studentData={studentData}/>) : '' }


{transactionData ? (
  <>
   <button onClick={handleBackClick} className="floating-back-button z-3">
        <FiArrowLeft size={24} />  
      </button>
  <Row className=" p-0  w-100" style={{margin: '10px 0 0 0'}}>
    {/* First Column */}
    <Col xs={12} sm={12} md={12} xl={6} className="m-0 p-0 w-100">
      <Card className="p-md-3 m-0 pb-4 w-100" style={{ borderRadius: '0px', boxShadow: "none", height: '100%' }}>
        <CardBody className="p-0 m-0 w-100" style={{ borderRadius: '0px', boxShadow: "none" }}>
          <CardTitle tag="h4" className="text-center font-weight-bold text-primary mb-4 pt-2">
            Transaction Details
          </CardTitle>
          <Row className="p-md-2 m-0">
            {/* Transaction Data */}
            <Col xs={12} sm={6} className="mb-3">
              <strong>Fee Types: </strong> {transactionData.feestype_id?.feesType || "N/A"}
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <strong>Receipt No: </strong> {transactionData.invoiceId || "N/A"}
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <strong>Payment Date: </strong> {formatDate(transactionData?.paymentDate) || "N/A"}
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <strong>Payment Method: </strong> {transactionData?.paymentMethod || "N/A"}
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <strong>Amount: </strong> {transactionData?.amount || "N/A"}
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <strong>Fine: </strong> {transactionData?.fine || "No Fine"}
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <strong>Next Due Date: </strong>{formatDate(transactionData?.dueDate) || "N/A"}
            </Col>
          </Row>
        </CardBody>
        <Row className="justify-content-center p-0 m-0 mt-0">
          <Col xs={12} sm={9} md={6} lg={4} xl={3}  className="text-center  me-sm-2 me-0 me-md-0">
            <Button
              color="primary"
              style={{ backgroundColor: loading ? 'black' : '', width: '100%', marginTop: '10px' }}
              onClick={handleGenerateInvoice}
              disabled={loading} // Only disable if loading
            >
              {loading ? (
                <>
                  <Spinner size="sm" /> Generating Invoice...
                </>
              ) : 'Generate Invoice'}
            </Button>
            <span style={{ fontSize: "14px", color: "black", display: 'block', marginTop: '5px' }}>
              Last Generated Date:<span style={{color:'#FB0F01'}}>  {lastdate}</span>
            </span>
            </Col>

            {invoice && (
            <Col  xs={12} sm={9} md={6} lg={4} xl={3}  className="text-center  ms-sm-4 ms-md-0 ms-0">
              <Button
                color="primary"
                style={{ width: '100%', marginTop: '10px' }}
                onClick={toggleModal} // Open the modal to view the invoice
              >
                View Invoice
              </Button>
            </Col>
            )}
        </Row>
      </Card>
    </Col>
  </Row>

  {/* Modal for Invoice Preview */}
  <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
    <ModalHeader toggle={toggleModal}>
      Invoice Preview
    </ModalHeader>
    <ModalBody>
      {invoice ? (
        <iframe
          src={invoice}
          title="Invoice PDF Preview"
          style={{ width: '100%', height: '420px', objectFit: 'contain' }}
        />
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          No Preview Available, Please Generate Invoice First
        </div>
      )}
    </ModalBody>
    <style>
        {`
          @media (max-width: 992px) {
            .responsive-row {
              width: 100%; /* 100% for small screens */
            }
          }
          @media (min-width: 992px) {
            .responsive-row {
              width: 70%; /* 50% for md and larger screens */
            }
          }
        `}
      </style>
    <ModalFooter className="d-flex flex-column flex-md-row justify-content-between align-items-start">
      {/* Responsive button container */}
      <div className="mb-2 mb-lg-0">
        <Button className="me-2" color="primary" onClick={() => handleDownload(invoice, 'Invoice')}>
          Download
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </div>

      {/* Email input field */}
     <Row className=" d-flex m-0 p-0 mt-1 responsive-row   text-end justify-content-center">
     <Col xs={12} lg={7}   className=" mt-lg-0 p-0 ">
      <FormGroup className="p-0 pe-lg-1 pe-0">
        <Input
          type="email"
          name="email"
          id="email"
          className="mt-2 mt-lg-0"
          value={email} // Bind the local state value
          onChange={(e) => setEmail(e.target.value)} // Update state on change
          required
        />
      </FormGroup>
    </Col>

     <Col xs={12} lg={5} className="p-0">
     <Button color="primary" className="mt-2 mt-lg-0 w-100" onClick={() => SendInvoice()}>
        {invoicesending ? (
          <>
            <Spinner size="sm" /> Sending Invoice...
          </>
        ) : 'Send mail'}
      </Button></Col>
     </Row>
    </ModalFooter>
  </Modal>
</>
) : null}

    </>
  );
};

export default generateInvoice;



