import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavItem,
  Dropdown,
  DropdownToggle,
  Button,
} from "reactstrap";

import { useDispatch } from "react-redux";
import { logoutSuccess } from "../Redux/AuthRelated/AuthSlice";
import Logo from "../assets/images/logos/tulipschoolerp-logo.webp";
import axiosInstance from '../middleware/axiosInstance';
import ProfileCard from "./ProfileCard";

const Header = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [organization, setOrganization] = useState('');
  const [username, setUsername] = useState('');


  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => setIsOpen(!isOpen);
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleLogOut = () => {
    dispatch(logoutSuccess());
  };


  const fetchOrganization = async () => {
    try {
      const response = await axiosInstance.get('/admindashboard');
      if (response.data && response.data.organization) {
        setOrganization(response.data.organization);
        setUsername(response.data.name);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  const getInitial = (name) => name.charAt(0).toUpperCase();

  return (
    <Navbar className="custom-navbar" dark expand="md">
      <div className="d-flex align-items-center">
          <button style={{ color: "white", backgroundColor: "#fb0f01" }} onClick={() => showMobilemenu()} className="bi bi-list d-lg-none px-2 py-1  border border-1 me-4 border-white"></button>
        <NavItem style={{ listStyle: "none" }}>
          <Link to="/admindashboard" >
            <img
              src={Logo}
              alt="Logo"
              className="custom-logo"
            />
          </Link>
        </NavItem>
      </div>
      <div className="hstack gap-2">
        <Button
          color="#fb0f01"
          size="sm"
          onClick={Handletoggle}
        >
        </Button>
      </div>
      <div className="d-flex align-items-center">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle style={{ backgroundColor: "#fb0f01", border: "none" }}>
            <div className="rounded-circle" style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#fff',
              color: '#fb0f01',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              fontWeight: 'bold'
            }}>
              {getInitial(username)}
            </div>
          </DropdownToggle>
          <ProfileCard toggle={toggle} handleLogOut={handleLogOut} />
        </Dropdown>
      </div>
    </Navbar>
  );
};

export default Header;
