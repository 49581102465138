import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import './FullLayout.css'
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { updateFirstLogin } from '../Redux/AuthRelated/AuthSlice'; 
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from '../middleware/ScrollToTop.js'

const FullLayout = () => {
  const dispatch = useDispatch();

  const isFirstLogin = useSelector((state) => state.auth.isfirstime);

  const handleClickOutside = (event) => {
    const sidebar = document.getElementById("sidebarArea");
    if (sidebar && !sidebar.contains(event.target) && !document.querySelector('.bi-list').contains(event.target)) {
      sidebar.classList.remove('showSidebar');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
};
}, []);

useEffect(() => {
  if (isFirstLogin) {
    Swal.fire({
      title: 'Welcome to Tulip!',
      text: 'We’re thrilled to have you on board! Enjoy exploring all the amazing features we have to offer. Your journey starts now!',
      confirmButtonText: 'Let\'s Get Started',
      footer: 'If you have any questions, feel free to reach out to our support team!'
    }).then(() => {
      dispatch(updateFirstLogin(false)); 
    });
  }
}, [isFirstLogin, dispatch]);
 
  return (
    <main>
      <div className="header"> 
      <Header />
      </div>
      <div className="pageWrapper d-lg-flex">
        <aside className="sidebarArea shadow" id="sidebarArea">
          <Sidebar />
        </aside>
        <div className="contentArea">        
          <Container className="p-2 " fluid>
          <ScrollToTop/>  
            <Outlet />
          </Container>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;